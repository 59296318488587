import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import { pick } from 'lodash-es'
import { whiteboardConstants } from '@/models/constants'

export type ShapeType = 'triangle' | 'rectangle' | 'circle' | 'star'

interface IWbShapeOptions extends fabric.IPathOptions {
  id?: string
  lock?: boolean
  preventUnlock?: boolean
}

export default class WbShape extends fabric.Path implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.shape
  public shapeType: ShapeType = 'rectangle'
  public lock: boolean
  public preventUnlock: boolean
  public connectable: boolean
  public editableProps: Record<string, IWbObjectProp> = {
    border: { name: 'border', type: 'border' },
    color: { name: 'color', type: 'color' },
    lock: { name: 'Lock', type: 'lock' },
    // 'shapeType': { name: 'shape', type: 'shapeType' },
    addDiscussion: { name: 'add comment', type: 'addDiscussion' },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    group: { action: 'group', label: 'Group', faicon: 'fa-light fa-object-group', showInSubMenu: true, multiple: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  static shapes: { [key in ShapeType]: string } = {
    triangle: 'M25,0 50,50 0,50 25,0',
    rectangle: 'M0,0 50,0 50,50 0,50 0,0',
    circle: 'M 0, 25 a 25,25 0 1,0 50,0 a 25,25 0 1,0 -50,0',
    star: 'm25,2l6,17l18,0l-14,11l5,17l-15,-10l-15,10l5,-17l-14,-11l18,0l6,-17z',
  }

  constructor(shapeType: ShapeType, opt?: IWbShapeOptions) {
    super(WbShape.shapes[shapeType], opt)
    this.id = opt?.id || guid()
    this.shapeType = shapeType
    this.lock = opt?.lock || false
    this.strokeUniform = true
    this.connectable = true
    this.preventUnlock = opt?.preventUnlock || false

    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['shapeType', 'lock', 'preventUnlock'])
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'border':
        this.set('stroke', value.borderColor)
        this.set('strokeWidth', value.strokeWidth)
        break
      case 'color':
        this.set('fill', value.backgroundColor)
        break
      case 'shapeType':
        this.set('path', WbShape.shapes[value.shapeType])
        this.set('shapeType', value.shapeType)
        // this.initialize(WbShape.shapes[value.shapeType], this.options)
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      case 'lockSkewingX':
        this.set('lockSkewingX', value)
        break
      case 'lockSkewingY':
        this.set('lockSkewingY', value)
        break
      case 'lockScalingX':
        this.set('lockScalingX', value)
        break
      case 'lockScalingY':
        this.set('lockScalingY', value)
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'border':
        result.borderColor = this.stroke
        result.strokeWidth = this.strokeWidth
        break
      case 'color':
        result.backgroundColor = this.fill
        break
      case 'shapeType':
        result.shapeType = this.shapeType
        result.path = this.path
        break
      case 'lock':
        result.lock = this.lock
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'shapeType',
      'type',
      'top',
      'left',
      'angle',
      'width',
      'height',
      'scaleX',
      'scaleY',
      'fill',
      'stroke',
      'strokeWidth',
      'lock',
      'preventUnlock',
      'angle',
      'backgroundColor',
      'path',
      'version',
      'opacity',
      'strokeMiterLimit',
      'strokeUniform',

    ]
    return pick(this, propsToPluck)
  }

  static fromObject(object: fabric.Object, callback?: Function) {
    return fabric.Object._fromObject(whiteboardConstants.objectTypes.shape, object, callback, 'shapeType') as WbShape
  }
}

const f: any = fabric
f.WbShape = WbShape
