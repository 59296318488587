<template>
  <div class="w-full grow flex flex-col h-full overflow-hidden">
    <tx-data-table
      :data="data" :columns="columns" :loading="loadingData" :item-actions="itemActions" :list-actions="listActions" :visible-columns="visibleColumns"
      :title="t('general.articles')" :model-path="modelPath" :total-column-width="totalColumnWidth" :sort-column="{ UpdatedDate: false }"
      @selection-changed="onSelectionChanged" @refresh="doRefresh"
    />
    <approve-reject-request-dialog ref="showApproveRejectDialog" @approve-reject="onApproveReject" />
    <edit-request-dialog ref="showEditRequestDialogRef" @edit-request="onEditRequest" />

    <tx-drawer v-model="isDrawerVisible" right width="700px">
      <active-or-deactive-article
        v-if="isStatusDrawerVisible"
        class="grow"
        :type="isActivate ? 'activate' : 'deactivate'" :show-header="true"
        :articles="[currentArticle!]" :confirm-request-id="confirmRequestId" @cancel="closeDrawer" @updated="onArticlesUpdated"
      />
      <manage-article-state
        v-else-if="isStateDrawerVisible"
        class="grow" :articles="[currentArticle!]" :confirm-request-id="confirmRequestId" :request-confirm-state-id="requestConfirmValue"
        :show-header="false" @cancel="closeDrawer" @updated="onArticlesUpdated"
      />
      <tx-data-importer v-else-if="importModel && selectedAction?.id === 'editRequestBulk'" ref="refDataImporter" :model="importModel" :action="selectedAction" :data="data" @import="onImport" @cancel="closeDrawer" />
    </tx-drawer>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import useUserProfileSettings from '../admin/composables/userProfileSettings'
import useArticleLocalDataUpdater from '../articleDetails/composables/articleLocalDataUpdater'
import ManageArticleState from '../articleDetails/components/ManageArticleState.vue'
import useRequestLocalDataUpdater from '../articleDetails/composables/requestLocalDataUpdater'
import type { IImportModel } from '../admin/type'
import RequestsModel from './requests'
import ActiveOrDeactiveArticle from '@/modules/articleDetails/components/ActiveOrDeactiveArticle.vue'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'
import TxDataTable from '@/shared/components/txDataTable'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type { ITxDataTableColumn, ITxDataTableItemAction, ITxDataTableListAction, ITxDataTableVisibleColumn } from '@/shared/components/txDataTable/TxDataTable.types'
import { useNotificationStore } from '@/store/notification'
import { appConstants, editRequestKeys, privileges, requestConstants } from '@/models/constants'
import type RequestModel from '@/models/request'
import ApproveRejectRequestDialog from '@/shared/components/requests/ApproveRejectRequestDialog.vue'
import { updateArticle, updateArticlePrices } from '@/api/t1/article'
import { editRequestBulkBgJob, updateRequestStatuses } from '@/api/t1/request'
import type MyArticle from '@/models/myArticle'
import { AttributeType } from '@/models/catalogAttribute'
import { updateModelSizeScale } from '@/api/t1/size'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import TxDataImporter from '@/shared/components/txDataImporter/TxDataImporter.vue'
import type { ArticlePriceModel, UpdateArticlePricesModel } from '@/api/t1/model/articleModel'
import ArticlePrice from '@/models/articlePrice'
import useEventsBus from '@/shared/composables/eventBus'
import utils from '@/services/utils'
import Job from '@/models/job'
import EditRequestDialog from '@/modules/articleDetails/components/EditRequestDialog.vue'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'

const { t } = useI18n()
const userStore = useUserStore()
const notificationsStore = useNotificationStore()
const { applyPersistedListViewLayout } = useUserProfileSettings()
const confirmDialog = useConfirmDialog()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const { emitter, onAppEvent, offAppEvent } = useEventsBus()
const { updateRequestsLocally } = useRequestLocalDataUpdater()
const { skipRestrictingPropertyUpdateBasedOnArticleState, getArticlesLocked, getArticlesMaxStateDetails, getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank, getIndexedRestrictedAttributesBasedOnArticlesStateRank } = useArticleFormHelper()

const columns = ref<ITxDataTableColumn[]>([])
const data = ref<any[]>([])
const loadingData = ref(true)
const visibleColumns = ref<ITxDataTableVisibleColumn[]>([])
const modelPath = ref('')
const totalColumnWidth = ref(1200)
const isDrawerVisible = ref(false)
const refDataImporter = ref<InstanceType<typeof TxDataImporter>>()
const importModel = ref<IImportModel>()
const selectedAction = ref<ITxDataTableListAction>()

const selectedRequests = ref<RequestModel[]>([])
const selectedItems = ref<RequestModel[]>([])
const requestsModel = shallowRef<RequestsModel>()
const showApproveRejectDialog = ref<InstanceType<typeof ApproveRejectRequestDialog>>()
const confirmRequestId = ref<number | null>(null)
const requestConfirmValue = ref<number | null>(null)
const currentArticle = ref<MyArticle | null>(null)
const isStatusDrawerVisible = ref(false)
const isStateDrawerVisible = ref(false)
const isActivate = ref(false)
const showEditRequestDialogRef = ref<InstanceType<typeof EditRequestDialog>>()
const isAllowedConfirmRequests = ref<{ [key: string]: boolean }>({})

interface UpdateRequestsStatusModel {
  Id: number
  Status: ZeroOrOneType
}
enum actionType {
  delete = 'delete',
  activate = 'activate',
  approve = 'approve',
  reject = 'reject',
  confirm = 'confirm',
  edit = 'edit',
  editRequestBulk = 'editRequestBulk',
}

const itemActions = computed(() => ([
  {
    id: actionType.edit,
    enabled: true,
    visible: item => (userStore.userProfile.isValidPrivilege(privileges.request.editRequest) && item.CatalogCode === userStore.activeCatalog?.CatalogCode) && (item.CreatedBy === userStore.userProfile.Id || userStore.userProfile.isValidPrivilege(privileges.request.specialPermissionToEditRequest)) && (item.State === requestConstants.requestStates.draft || item.State === requestConstants.requestStates.new),
    icon: 'fa-light fa-edit',
    label: t('requestsTable.actions.editRequest'),
    onClick: (item) => {
      openDrawer(actionType.edit, item)
    },
  },
  {
    id: actionType.delete,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.request.updateStatus) && item.CatalogCode === userStore.activeCatalog?.CatalogCode && item.Status > 0 && (item.State === requestConstants.requestStates.draft || item.State === requestConstants.requestStates.new),
    icon: 'fa-light fa-trash-can',
    label: t('general.delete'),
    onClick: (item) => {
      onActivateDeactivate([item], 0)
    },
  },
  // {
  //   id: actionType.activate,
  //   enabled: true,
  //   visible: item => userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && item.Status === 0,
  //   icon: 'fa-light fa-rotate-right',
  //   label: t('activeOrDeactiveArticle.activate'),
  //   onClick: async (item) => {
  //     selectedRequests.value = [item]
  //     openDrawer(actionType.activate)
  //   },
  // },
  {
    id: actionType.approve,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.request.approveRequest) && item.CatalogCode === userStore.activeCatalog?.CatalogCode && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.reject),
    label: t('requests.approve'),
    icon: 'fa-light fa-thumbs-up',
    onClick: (item) => {
      selectedRequests.value = [item]
      openApproveRejectDialog(selectedRequests.value, actionType.approve)
    },
  },
  {
    id: actionType.reject,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.request.rejectRequest) && item.CatalogCode === userStore.activeCatalog?.CatalogCode && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.approve),
    label: t('requests.reject'),
    icon: 'fa-light fa-thumbs-down',
    onClick: (item) => {
      selectedRequests.value = [item]
      openApproveRejectDialog(selectedRequests.value, actionType.reject)
    },
  },
  {
    id: actionType.confirm,
    enabled: true,
    visible: item => userStore.userProfile.isValidPrivilege(privileges.request.confirmRequest) && isAllowedConfirmRequests.value[item.Id],
    label: t('requests.confirm'),
    icon: 'fa-light fa-paper-plane',
    onClick: (item) => {
      onConfirmRequest(item)
    },
  },
]) as Array<ITxDataTableItemAction>)

const listActions = computed(() => {
  const actions: ITxDataTableListAction[] = [{
    id: actionType.delete,
    label: t('general.delete'),
    icon: 'fa-light fa-trash-can',
    visible: userStore.userProfile.isValidPrivilege(privileges.request.updateStatus),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.CatalogCode === userStore.activeCatalog?.CatalogCode) && selectedItems.value.every(x => x.Status > 0),
    onClick: () => {
      onActivateDeactivate(selectedItems.value, 0)
    },
  },
  // {
  //   id: actionType.activate,
  //   label: t('activeOrDeactiveArticle.activate'),
  //   icon: 'fa-light fa-rotate-right',
  //   visible: userStore.userProfile.isValidPrivilege(privileges.request.updateStatus),
  //   enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.Status === 0),
  //   onClick: () => {
  //     selectedRequests.value = selectedItems.value
  //     openDrawer(actionType.activate)
  //   },
  // },
  {
    id: actionType.approve,
    label: t('requests.approve'),
    icon: 'fa-light fa-thumbs-up',
    visible: userStore.userProfile.isValidPrivilege(privileges.request.approveRequest),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.CatalogCode === userStore.activeCatalog?.CatalogCode) && selectedItems.value.every(x => x.State === requestConstants.requestStates.new || x.State === requestConstants.requestStates.reject),
    onClick: () => {
      selectedRequests.value = selectedItems.value
      openApproveRejectDialog(selectedRequests.value, actionType.approve)
    },
  }, {
    id: actionType.reject,
    label: t('requests.reject'),
    icon: 'fa-light fa-thumbs-down',
    visible: userStore.userProfile.isValidPrivilege(privileges.request.rejectRequest),
    enabled: selectedItems.value.length > 0 && selectedItems.value.every(x => x.CatalogCode === userStore.activeCatalog?.CatalogCode) && selectedItems.value.every(x => x.State === requestConstants.requestStates.new || x.State === requestConstants.requestStates.approve),
    onClick: () => {
      selectedRequests.value = selectedItems.value
      openApproveRejectDialog(selectedRequests.value, actionType.reject)
    },
  }, {
    id: actionType.editRequestBulk, // edit request attributes bulk
    label: t('requestsTable.actions.editRequestBulk'),
    icon: 'fa-light fa-file-import',
    visible: userStore.userProfile.isValidPrivilege(privileges.request.editRequestBulk),
    enabled: true,
    onClick: () => {
      openDrawer(actionType.editRequestBulk)
    },
  }]
  return actions
})

onMounted(async () => {
  requestsModel.value = new RequestsModel(t)
  columns.value = requestsModel.value.getDataTableColumns()
  visibleColumns.value = requestsModel.value.getDefaultVisibleColumnList()
  modelPath.value = requestsModel.value.modelPath
  // retrieve persisted default colums for model if existed and apply that on visible columns
  // Note: This should be part of all module implementations.
  const { persistedColumns, totalTableColumnWidth } = applyPersistedListViewLayout(true, modelPath.value, columns.value, visibleColumns.value)
  if (persistedColumns) {
    visibleColumns.value = persistedColumns
  }
  if (totalTableColumnWidth) {
    totalColumnWidth.value = totalTableColumnWidth
  }
  await doRefresh()
  onAppEvent('catalogDataUpdated', doRefresh)
})

onUnmounted(() => {
  offAppEvent('catalogDataUpdated', doRefresh)
})
// check if user is allowed confirm the request,
// should have specific privilege, attribute should be editable,
// should not be restricted at state and role level(role level attributes comes as non editable)
async function isAllowedConfirm(request: RequestModel) {
  if (request.IsCreateArticleRequest) {
    return true
  }
  let isAllowedConfirmRequest = false
  const { userProfile, activeCatalog } = userStore
  const priceGroups = userStore.activeCatalog!.CatalogPriceGroupList
  const { AccountDetails } = userProfile
  const isAccountValid = AccountDetails.AccountTypeId === 1 || AccountDetails.AccountId === activeCatalog?.AccountId
  const articleObject: MyArticle | undefined = await appConfig.DB!.getArticle(userStore.activeCatalog!, request.SourceArticleId!)
  if (articleObject) {
    const key = Object.keys(request.Content)[0]
    const maxArticleStateRankDetails = getArticlesMaxStateDetails([articleObject], (key === editRequestKeys.prices ? !!userStore.activeCatalog!.IsPriceByStyleApply : key === editRequestKeys.sizeScale ? !!userStore.sellerDetails?.IsSizeScaleByStyle : key === editRequestKeys.modelName))
    if (request.RequestType === requestConstants.requestTypes.EditAttribute.key) {
      const attribute = userStore.myAttributes![key]
      const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
      if (hasPrivilege && isAccountValid && attribute.Editable) {
        const indexedRestrictedAttributesBasedOnArticlesMaxSateRank = getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank([articleObject])
        const indexedRestrictedAttributesBasedOnArticlesStateRank = getIndexedRestrictedAttributesBasedOnArticlesStateRank([articleObject])
        if (!attribute.ReadOnly && ((attribute.IsModelLevel && !indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(attribute.SystemName)) || (!attribute.IsModelLevel && !indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(attribute.SystemName)))) {
          isAllowedConfirmRequest = true
        }
      }
    }
    else {
      // static properties
      if (key === editRequestKeys.stateName) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.AssignArticleStateToArticles)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.modelName) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
          if (utils.isDefined(maxArticleStateRankDetails)) {
            // if user has special permission assigned to his role then skip restriction
            if (!skipRestrictingPropertyUpdateBasedOnArticleState) {
              isAllowedConfirmRequest = maxArticleStateRankDetails.IsModelNameEditable === 1
            }
          }
        }
      }
      else if (key === editRequestKeys.prices) {
        const hasPrivilege = userProfile.isValidPrivilege(privileges.article.UpdateCatalogModelPrices)
          || userProfile.isValidPrivilege(privileges.article.UpdateCatalogArticleBulkPrices)
        if (hasPrivilege && isAccountValid) {
          const isAnyArticleLocked = await getArticlesLocked([articleObject], !!activeCatalog?.IsPriceByStyleApply)

          if (!isAnyArticleLocked || userProfile.isValidPrivilege(privileges.request.ignoreArticlePriceLockInConfirmPriceRequest)) {
            let allEditableAndNotRestricted = true

            for (const price of request.Content.Prices) {
              const priceGroup = priceGroups.filter(pg => pg.Id === price.Id)[0]
              if (priceGroup && maxArticleStateRankDetails) {
                const priceGroupStateRank = priceGroup.StateRank || 0
                const lockBasedOnArticlesState = !skipRestrictingPropertyUpdateBasedOnArticleState
                  && priceGroup.StateRank != null && maxArticleStateRankDetails.StateRank >= priceGroupStateRank
                if (!priceGroup.IsEditable || lockBasedOnArticlesState) {
                  allEditableAndNotRestricted = false
                  break
                }
              }
              else {
                allEditableAndNotRestricted = false
                break
              }
            }

            if (allEditableAndNotRestricted) {
              isAllowedConfirmRequest = true
            }
          }
        }
      }
      else if (key === editRequestKeys.status) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.updateStatus)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.colorId) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
        if (hasPrivilege && isAccountValid && userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && userStore.sellerDetails?.AutomaticNumbering === 1 && (articleObject.ColorId === null || articleObject.ColorId === 0)) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.sizeScale) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.updateSizeScale)
        const lockedBasedOnArticleState = (maxArticleStateRankDetails == null || !maxArticleStateRankDetails.IsSizeScaleEditable) && !skipRestrictingPropertyUpdateBasedOnArticleState
        if (hasPrivilege && isAccountValid && userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited && userStore.sellerDetails?.IsSizeScaleByStyle && !lockedBasedOnArticleState) {
          isAllowedConfirmRequest = true
        }
      }
    }
  }
  return isAllowedConfirmRequest
}
function onSelectionChanged(items: any[]) {
  selectedItems.value = items
}

async function doRefresh() {
  loadingData.value = true
  if (userStore.activeCatalog) {
    selectedItems.value = []
    data.value = []
    await userStore.doLoadData(['Requests'], [], false)
    // fetch requests which is from another catalog and current catalog is part of the impacted catalogs list
    const response = await appConfig.DB!.requests
      .toArray()
      .then(allRequests =>
        allRequests
          .filter(art =>
            art.CatalogCode === userStore.activeCatalog!.CatalogCode
            || (art.ImpactedCatalogs && art.ImpactedCatalogs.some(x => x.CatalogCode === userStore.activeCatalog!.CatalogCode)),
          ),
      )
    data.value = await utils.getModifyArticleRequestsTranslatedData(userStore.activeCatalog, userStore.myAttributes!, response, userStore.sizeScales, userStore.articleStateList, appConfig.DB!, userStore.priceGroups)
    if (userStore.userProfile.isValidPrivilege(privileges.request.confirmRequest)) {
      for (const item of data.value) {
        if (item.CatalogCode === userStore.activeCatalog?.CatalogCode && item.State === requestConstants.requestStates.approve) {
          isAllowedConfirmRequests.value[item.Id] = await isAllowedConfirm(item)
        }
        else {
          isAllowedConfirmRequests.value[item.Id] = false
        }
      }
    }
  }
  loadingData.value = false
}

function openDrawer(action: actionType, request?: RequestModel) {
  selectedAction.value = listActions.value.find(listAction => listAction.id === action)
  importModel.value = undefined
  if (action === actionType.edit && request) {
    showEditRequestDialogRef.value?.showDialog(request)
  }
  else if (action === actionType.editRequestBulk && requestsModel.value) {
    importModel.value = requestsModel.value.getImportData(action)
    isDrawerVisible.value = true
  }
}

function openApproveRejectDialog(requests: any[], action: actionType) {
  const actionNumber = action === actionType.approve ? requestConstants.requestStates.approve : requestConstants.requestStates.reject
  showApproveRejectDialog.value?.showDialog(requests, actionNumber)
}

async function onApproveReject(requestIds: number[], propertiesToUpdate: { state: number, comment: string, reason: string, updatedByUserName: string }) {
  await updateRequestsLocally(requestIds, propertiesToUpdate)
  doRefresh()
}

async function onConfirmRequest(request: any) {
  if (userStore.activeCatalog && request.Content) {
    confirmRequestId.value = null
    const title = t('general.alert')
    let message = t('requests.confirmRequestMessage')
    // add comment and reason
    const confirmContentArray: any[] = []
    confirmContentArray.push({ header: `<strong>${t('requests.title.approvalRejectionReason')}:</strong>` })
    confirmContentArray.push(`${t('requestsTable.fields.reason')}: ${request.Reason}`)
    confirmContentArray.push(`${t('requestsTable.fields.comment')}: ${request.Comment}`)

    const attributesList = Object.values(userStore.myAttributes!)
    const modelLevelAttributeNames = attributesList
      .filter(attribute => attribute.IsModelLevel)
      .map(attribute => attribute.SystemName)

    const key = Object.keys(request.Content)[0]
    // show impacted seasons for confirm edit prices/ seasonless attributes request
    if (request.ImpactedCatalogs && request.ImpactedCatalogs.length) {
      const uniqueSeasons = [...new Set(request.ImpactedCatalogs.map(season => season.Season))].join(',')
      const affectedSeasonsString = uniqueSeasons
      message = t('requests.confirmEditPricesRequestMessage', { seasons: affectedSeasonsString })
    }

    await confirmDialog(title, message, confirmContentArray, async () => {
      let newValue = request.Content[key]
      const propertiesToUpdate = { state: requestConstants.requestStates.confirm, comment: request.Comment, reason: request.Reason, updatedByUserName: userStore.currentUsername }
      const attr = attributesList.filter(attribute => attribute.SystemName === key)[0]
      const articleObject: MyArticle | undefined = await appConfig.DB!.getArticle(userStore.activeCatalog!, request.SourceArticleId)
      if (articleObject) {
        currentArticle.value = articleObject
        if (attr && (!attr.IsStatic || key === 'ModelName')) {
          const updatePayload = {
            ModelId: articleObject.ModelId,
            ModelNumber: articleObject.ModelNumber,
            ArticleNumber: articleObject.ArticleNumber,
            ArticleName: articleObject.ArticleName,
          }
          if (attr.AttributeType === AttributeType.ColorPalette) {
            newValue = JSON.stringify(newValue)
          }
          updatePayload[key] = newValue
          try {
            await updateArticle(userStore.activeCatalog!.CatalogCode, articleObject.Id, updatePayload, modelLevelAttributeNames.includes(key), request.Id)
            await refreshLocalArticlesData(true, articleObject.ModelId)
            await updateRequestsLocally([request.Id], propertiesToUpdate)
            doRefresh()
            notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
          }
          catch (error) {
            console.error(error)
          }
        }
        if ((attr && attr.IsStatic) || key === editRequestKeys.prices) {
          if (key === editRequestKeys.stateName) {
            requestConfirmValue.value = Number.parseInt(newValue)
            confirmRequestId.value = request.Id
            isDrawerVisible.value = true
            isStateDrawerVisible.value = true
          }
          else if (key === editRequestKeys.colorId) {
            const updatePayload = {
              ModelId: articleObject.ModelId,
              ModelNumber: articleObject.ModelNumber,
              ArticleNumber: articleObject.ArticleNumber,
              ArticleName: articleObject.ArticleName,
            }
            const colorId = newValue ? Object.keys(newValue)[0] : newValue
            updatePayload[key] = colorId
            try {
              await updateArticle(userStore.activeCatalog!.CatalogCode, articleObject.Id, updatePayload, modelLevelAttributeNames.includes(key), request.Id)
              await refreshLocalArticlesData(true, articleObject.ModelId)
              await updateRequestsLocally([request.Id], propertiesToUpdate)
              doRefresh()
              notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              console.error(error)
            }
          }
          else if (key === editRequestKeys.status) {
            requestConfirmValue.value = Number.parseInt(newValue)
            confirmRequestId.value = request.Id
            isActivate.value = newValue === 1
            isDrawerVisible.value = true
            isStatusDrawerVisible.value = true
          }
          else if (key === editRequestKeys.sizeScale) {
            try {
              await updateModelSizeScale(userStore.activeCatalog!.CatalogCode, request.SourceModelId!, { SizeScaleId: newValue }, request.Id)
              await refreshLocalArticlesData(true, articleObject.ModelId)
              await updateRequestsLocally([request.Id], propertiesToUpdate)
              doRefresh()
              notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              console.error(error)
            }
          }
          else if (key === editRequestKeys.prices) {
            const requestedPrices = request.Content.Prices.map(contentPrice => ({
              PriceGroupId: contentPrice.Id,
              Price: Number.parseFloat(contentPrice.Price),
            }))

            const articleIdField = userStore.activeCatalog?.IsPriceByStyleApply ? 'ModelNumber' : 'Id'
            const payload: UpdateArticlePricesModel = {
              Prices: requestedPrices,
            }

            try {
              const response = await updateArticlePrices(userStore.activeCatalog!.CatalogCode, articleObject[articleIdField], payload, userStore.activeCatalog!.IsPriceByStyleApply, request.Id)

              await refreshLocalArticlesData(true, articleObject.ModelId)
              await updateRequestsLocally([request.Id], propertiesToUpdate)
              doRefresh()

              // update local articles data
              const responseArticleMap: Record<number, ArticlePriceModel[]> = {}

              if (response.data && Array.isArray(response.data)) {
                response.data.forEach((item: ArticlePriceModel) => {
                  if (!responseArticleMap.hasOwnProperty(item.ArticleId)) {
                    responseArticleMap[item.ArticleId] = []
                  }
                  responseArticleMap[item.ArticleId].push(item)
                })
              }

              const articlePriceData: ArticlePrice[] = []
              for (const articleId in responseArticleMap) {
                const articlePrice = { Id: Number(articleId), Prices: responseArticleMap[articleId] }
                articlePriceData.push(new ArticlePrice(userStore.activeCatalog!.CatalogCode, articlePrice))
              }

              await appConfig.DB!.bulkUpdateArticles(userStore.activeCatalog!.CatalogCode, 'prices', articlePriceData, true)
              emitter('catalogDataUpdated', { source: 'Price' })

              notificationsStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              console.error(error)
            }
          }
        }
      }
    })
  }
}

async function onActivateDeactivate(requests: RequestModel[], status: ZeroOrOneType) {
  if (userStore.activeCatalog) {
    const artcileNumbers: string[] = []
    const requestObj: UpdateRequestsStatusModel[] = []
    requests.forEach((request) => {
      artcileNumbers.push(request.SourceArticleNumber)
      requestObj.push({
        Id: request.Id,
        Status: status,
      })
    })
    const title = t('general.alert')
    const activateOrDeactivateAction = status === 0 ? 'delete' : 'activate'
    const message = t('activeOrDeactiveArticle.activateOrDeactivateAlert', { action: activateOrDeactivateAction, number: artcileNumbers.toString() })

    await confirmDialog(title, message, [], async () => {
      await updateRequestStatuses(userStore.activeCatalog!.CatalogCode, requestObj)
      notificationsStore.addNotification({ message: t(status === 0 ? 'activeOrDeactiveArticle.deactivateRequestSuccessfully' : 'activeOrDeactiveArticle.activateRequestSuccessfully', 1), type: 'Success' })
      doRefresh()
    })
  }
}

async function onArticlesUpdated() {
  const message = t('general.updatedSuccessfully')
  notificationsStore.addNotification({ message, type: 'Success' })
  doRefresh()
  closeDrawer()
}

async function onEditRequest() {
  doRefresh()
}

function onImport(data: any[]) {
  if (selectedAction.value) {
    switch (selectedAction.value.id) {
      case actionType.editRequestBulk:
        submitImportedData(data)
        break
    }
  }
}

async function submitImportedData(validData: any[]) {
  await editRequestBulkBgJob(validData).then(async (res) => {
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'editRequestBulk',
        Status: res.data.Status,
        UserId: userStore.userProfile.Id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.requests.messages.editRequestBulk')
      notificationsStore.addNotification({ message, type: 'Success' })
    }
  })
    .catch((e) => {
      console.error(e)
    })
    .finally()
}

function closeDrawer() {
  isStateDrawerVisible.value = false
  isStatusDrawerVisible.value = false
  isDrawerVisible.value = false
}
</script>
